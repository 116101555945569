import naja from 'naja';
import { LiveForm, Nette } from 'live-form-validation';
import Inputmask from 'inputmask';
import $ from 'jquery';
import Swal from 'sweetalert2';
import qrcode from 'qrcode-generator'; 

import css from "./styles.scss";

LiveForm.setOptions({
    showValid: true,
    controlErrorClass: 'input_holder--error',
    controlValidClass: 'input_holder--success',
    showAllErrors: true,
    messageErrorPrefix: '',
    showMessageClassOnParent: 'form-group'
});

global.Nette = Nette;
global.LiveForm = LiveForm;

const NajaLiveValidationFallback = {
    initialize(naja){
        naja.addEventListener('init', function() {
            document.querySelectorAll('input[type="submit"], input[type="button"], form button').forEach(element => {
                element.addEventListener('click', this.processForm);
            });
        }.bind(this));
    },

    processForm(event) {
        const element = event.target;
        if (element.form !== undefined && element.form !== null) {
            element.form['nette-submittedBy'] = element;
        }
    }
}

const NajaPreventDoubleSubmission = {
    initialize(naja){
        naja.addEventListener('init', function() {
            // Prevent Double Submits
            document.querySelectorAll('form').forEach(form => {
                form.addEventListener('submit', (e) => {
                    if ((typeof form.validationState === "undefined") || (form.validationState == true)){
                        // Prevent if already submitting
                        if (form.classList.contains('is-submitting')) {
                            e.preventDefault();
                        }
                        // Add class to hook our visual indicator on
                        form.classList.add('is-submitting');
                    }
                });
            });
        }.bind(this));
    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-nette-rules]').forEach(function (elem) {
        elem.addEventListener('change', (event) => {
            elem.validationState = 'changed';
        }, true)
    });
    naja.registerExtension(NajaLiveValidationFallback);
    naja.registerExtension(NajaPreventDoubleSubmission);
    naja.initialize();
    Nette.initOnLoad();
    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        $(event.snippet).find('form').each(function() {
            window.Nette.initForm(this);
        });
        $(event.snippet).find('.fade-on-load').each(function(){
            $(this).hide().fadeIn();
        });
    });
    Inputmask().mask(document.querySelectorAll('input[data-inputmask]'));

    Array.from(document.getElementsByClassName("generate-qr")).forEach((e)=>{
        var qr = qrcode(e.dataset.type ?? 0, e.dataset.erroCorrection ?? 'L');
        qr.addData(e.dataset.data);
        qr.make();
        e.innerHTML =qr.createSvgTag({
            "cellSize" : 4,
            "margin" : 4,
            "scalable" : false
        });
    });
    Array.from(document.querySelectorAll('[data-display-after]')).forEach((e) => {
        const displayDate = new Date(e.dataset.displayAfter * 1000);
        const waitFor = displayDate - Date.now();
        var showElement = ()=>{e.classList.remove('d-none')};
        if (waitFor <= 0){
            showElement()
        } else {
            setTimeout(showElement, waitFor);
        }
    });

});
Nette.origIsDisabled = Nette.isDisabled;
Nette.isDisabled = function(elem){
    var result = Nette.origIsDisabled(elem);
    if (!result){
        result = elem.classList.contains('validation-waiting');
    }
    return result;
}

Nette.origValidateForm = Nette.validateForm;
Nette.validateForm = function(sender, onlyCheck){
    var result = Nette.origValidateForm(sender, onlyCheck);
    if (result){
        Array.from(sender.querySelectorAll('template.fire-on-submit')).forEach((template) => {
            if (!result) return;
            var style = window.getComputedStyle(template.parentElement);
            if (style.display !== 'none'){
                result = false;
                Swal.fire({template: template}).then((swalResult) => {
                    if (swalResult.isConfirmed){
                        sender.classList.add("is-submitting");
                        sender.submit();
                    } else {
                        sender.classList.remove("is-submitting");
                    }
                });
            }
        });
    };
    sender.validationState = result;
    return result;
}

Nette.validators.UIFormJavascriptCallbackValidator_validate = function(elem, args, val) {
    if (args.hasOwnProperty('callback')) {
        if (elem.hasOwnProperty('validationState')){
            switch (elem.validationState){
                case 'valid' : 
                    return true;
                case 'invalid' : 
                    return false;
                case 'waiting' :
                    return ;
            }
        } else {
            elem.addEventListener('change', (event) => {
                elem.validationState = 'changed';
            }, true)
        }
        elem.classList.add('validation-waiting');
        elem.validationState = 'waiting';
                // pro naja 2.5 lze použít string pro identifikaci stornovaného požadavku
        var uniqueId = elem.form.id + '-' + elem.name;
        naja.makeRequest('GET', args.callback, { value: val }, { history: false, snippets: true, abort: false, unique: uniqueId })
            .then((response) => {
                elem.classList.remove('validation-waiting');
                elem.validationState = response.result ? 'valid' : 'invalid';
                if (response.result) {
                    LiveForm.removeError(elem);
                } else {
                    LiveForm.addError(elem,
                        response.hasOwnProperty('message') ? response.message :
                            args.hasOwnProperty('message') ? args.message :
                                'Please enter a valid value.'
                    );
                }
            })
        ;
        return ;
    }
    return true;
};

$(document).on('click', '.toggle_box__item-header', function() {
    $(this).closest(".toggle_box__item").toggleClass('active');
}).on('click', '.main_menu_toggler', function() {
    $(this).toggleClass("active").find('.menu_icon').toggleClass('open');
    $('.main_menu').fadeToggle();
}).on('click', '.fake_select__title', function() {
    $(this).closest('.fake_select').toggleClass('active');
}).on('click', '.brand_select__item', function() {
    $(".device_select__item input:radio[name='zarizeni']").prop('checked', false);
    $(".fake_select__inner input:radio[name='barva']").prop('checked', false);
    $('.fake_select__title.model').html('Potom vyberte model');
    $('.fake_select__title.barva').html('Vyberte farbu zariadenie');
}).on('click', '.device_select__item', function() {
    $('.fake_select__title').html('Vyberte farbu zariadenie');
    $(".fake_select .fake_select__inner input:radio[name='barva']").prop('checked', false);
}).on("click", function(event){
    if(!$(event.target).closest(".fake_select").length){
        $(".fake_select").toggleClass('active', false);
    }
}).on('click', '.fake_select label', function() {
    const $fakeSelect = $(this).closest('.fake_select');
    $fakeSelect.find('.fake_select__title').html('<div class="color_selected">' + $(this).html() + '</div>');
    $fakeSelect.find('.fake_select__inner input').checked = true;
    $fakeSelect.toggleClass('active');
}).on('keypress', 'form', function (e) {
    var code = e.keyCode || e.which;
    if (code == 13) {
        e.preventDefault();
        $('input[type=submit][name=submit]').click();
        return false;
    }
}).on('submit', 'form', function() {
    var $this = $(this);
    if (typeof $this.valid == 'undefined'){
        return true;
    }
    if (!$(this).valid()) return false;
    $('input[type=submit]').attr("disabled", "disabled");
    return true;
}).on('click', '.modal_wrapper .close_modal', function(e){
    e.preventDefault();
    $(this).closest(".modal_wrapper").fadeOut();
});

function respondToVisibility(element, callback) {
    var options = {
      root: document.documentElement,
    };
    var observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        callback(entry.intersectionRatio > 0);
      });
    }, options);
    observer.observe(element);
  }

  Array.from(document.getElementsByClassName("toggle-inner-modal")).forEach(
      function(element){
          respondToVisibility(element, visible => {
              Array.from(element.getElementsByClassName("modal_wrapper")).forEach(e => {
                  e.style.display = visible ? 'block' : null;
              });
              Array.from(element.querySelectorAll('template.fire-on-show')).forEach(template=> {
                  if (visible){
                      Swal.fire({template: template});
                  }
              })
          });
      }
  )